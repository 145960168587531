"use client";

import React from "react";
import Link from "next/link";
import { Button } from "@nextui-org/button";
import staticImageLoader from "~/loaders/static-image-loader";
import style from "./header.module.css";
import HeroImage from "./girl.png";
import Image from "next/image";

export default function Header() {
    return (
        <header className={style.header}>
            <div className={`max-w-screen-xl ${style.content}`}>
                <div className={style.text}>
                    <div className={`${style.textbox} text-center md:text-left text-foreground max-sm:mt-6`} style={{
                        fontDisplay: 'swap'
                    }}>
                        <h1>Velkommen til <span>Ascendi</span></h1>
                        <p>
                            Vi tilbyr heldøgns bo- og omsorgstjenester til de som har behov for det i en støttende og trygg atmosfære. 
                            Vårt team av fagpersoner, miljøarbeidere og faglige nettverk er opptatt av å gi helse- og 
                            omsorgstjenester. Pårørende er velkomne til overnatting etter avtale.
                        </p>
                        <Link href="kontakt" className="lg:w-64 w-full max-sm:p-8 content-center">
                            <Button 
                                className="font-bold text-lg"
                                style={{ width:"100%" }}
                                color="warning" 
                                radius="md"
                            >
                                Ta Kontakt!
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className={style.img + " select-none"}>
                    {/* 35 rem */}
                    <div style={{ position:'relative', width:'100%', height:'100%', margin:'0 auto' }} className="flex flex-column justify-center">
                        <Image
                            style={{ objectFit: "contain", paddingRight:'3rem', userSelect:"none" }}
                            src={HeroImage}
                            width={HeroImage.width}
                            height={HeroImage.height}
                            loader={staticImageLoader}
                            sizes="33vw"
                            alt=""
                            // priority
                        />
                    </div>
                </div>
            </div>
        </header>
    )
}